<template>
  <b-container class="c-fabric" v-if="fabricArray">
    <b-row v-for="i in rows" :key="'r'+i" class="text-center">
      <template v-if="i<=fabricArray.length">
        <b-col v-for="j in cols" :key="'c'+j">
          <template v-if="fabricArray[i-1] && j<=fabricArray[i-1].length">
            <b-avatar v-if="fabricArray[i - 1][j - 1].name===selected" badge square badge-variant="success" size="4rem"
                      :src="fabricArray[i - 1][j - 1].img">
              <template slot="badge">
                <b-icon-check></b-icon-check>
              </template>
            </b-avatar>
            <b-avatar v-else square button size="4rem" :src="fabricArray[i - 1][j - 1].img"
                      @click="handleSelect(fabricArray[i - 1][j - 1].name)"></b-avatar>
            <p style="padding-top: 5px;">{{ fabricArray[i - 1][j - 1].name }}</p>
          </template>
        </b-col>
      </template>
    </b-row>
  </b-container>
</template>

<script>
import {fabricArray} from '../assets/js/resource.js';

export default {
  name: "CFabric",
  data() {
    return {
      rows: 3,
      cols: 3,
      fabricArray: fabricArray
    };
  },
  props: {
    selected: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleSelect(name) {
      this.$emit('select', name);
    }
  }
};
</script>

<style lang="scss" scoped>
.c-fabric {
  img {
    cursor: pointer;

    &.selected {
      border: solid red 2px;
    }
  }
}
</style>