<template>
  <div class="c-measurement">
    <b-tabs>
      <b-tab title="Jacket" v-if="clothingTypeArr.indexOf('C')>-1">
        <b-row style="margin-top: 10px;">
          <b-col>
            <b-card header-bg-variant="transparent">
              <template slot="header">
                <b-row align-v="center">
                  <b-col>
                    <span>Body</span>
                  </b-col>
                  <b-col>
                    <b-form-radio-group id="radio-group-jacket-body" v-model="jacket.body.unit" class="text-center">
                      <b-form-radio value="inch">inch</b-form-radio>
                      <b-form-radio value="cm">cm</b-form-radio>
                    </b-form-radio-group>
                  </b-col>
                </b-row>
              </template>
              <b-row>
                <b-col>
                  <label for="input-neck-j-b">NECK[V]:</label>
                  <b-input-group :append="jacket.body.unit" size="sm">
                    <b-form-input id="input-neck-j-b" v-model="jacket.body.neck" style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-chest-j-b">CHEST[V]:</label>
                  <b-input-group :append="jacket.body.unit" size="sm">
                    <b-form-input id="input-chest-j-b" v-model="jacket.body.chest" style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="input-stomach-j-b">STOMACH(JKT WAIST):</label>
                  <b-input-group :append="jacket.body.unit" size="sm">
                    <b-form-input id="input-stomach-j-b" v-model="jacket.body.stomach"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-seat-j-b">SEAT(HIPS):</label>
                  <b-input-group :append="jacket.body.unit" size="sm">
                    <b-form-input id="input-seat-j-b" v-model="jacket.body.seat" style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="input-bicep-j-b">BICEP:</label>
                  <b-input-group :append="jacket.body.unit" size="sm">
                    <b-form-input id="input-bicep-j-b" v-model="jacket.body.bicep" style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-shoulder-j-b">SHOULDER[V]:</label>
                  <b-input-group :append="jacket.body.unit" size="sm">
                    <b-form-input id="input-shoulder-j-b" v-model="jacket.body.shoulder"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="input-leftSlvLen-j-b">LEFT SLV LEN:</label>
                  <b-input-group :append="jacket.body.unit" size="sm">
                    <b-form-input id="input-leftSlvLen-j-b" v-model="jacket.body.leftSlvLen"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-rightSlvLen-j-b">RIGHT SLV LEN:</label>
                  <b-input-group :append="jacket.body.unit" size="sm">
                    <b-form-input id="input-rightSlvLen-j-b" v-model="jacket.body.rightSlvLen"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="input-frontShoulder-j-b">FRONT SHOULDER[V]:</label>
                  <b-input-group :append="jacket.body.unit" size="sm">
                    <b-form-input id="input-frontShoulder-j-b" v-model="jacket.body.frontShoulder"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-napeToWaist-j-b">NAPE TO WAIST[V]:</label>
                  <b-input-group :append="jacket.body.unit" size="sm">
                    <b-form-input id="input-napeToWaist-j-b" v-model="jacket.body.napeToWaist"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="input-backJktLen-j-b">BACK JKT LEN:</label>
                  <b-input-group :append="jacket.body.unit" size="sm">
                    <b-form-input id="input-backJktLen-j-b" v-model="jacket.body.backJktLen"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-neckShPt2Wst-j-b">NECK SH PT 2 WST[V]:</label>
                  <b-input-group :append="jacket.body.unit" size="sm">
                    <b-form-input id="input-neckShPt2Wst-j-b" v-model="jacket.body.neckShPt2Wst"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="input-jacketFitStyle-j-b">JACKET FIT STYLE:</label>
                  <b-input-group :append="jacket.body.unit" size="sm">
                    <b-form-input id="input-jacketFitStyle-j-b" v-model="jacket.body.jacketFitStyle"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col>
            <b-card header-bg-variant="transparent">
              <template slot="header">
                <b-row align-v="center">
                  <b-col>
                    <span>Finished</span>
                  </b-col>
                  <b-col>
                    <b-form-radio-group id="radio-group-jacket-finished" v-model="jacket.finished.unit"
                                        class="text-center">
                      <b-form-radio value="inch">inch</b-form-radio>
                      <b-form-radio value="cm">cm</b-form-radio>
                    </b-form-radio-group>
                  </b-col>
                </b-row>
              </template>
              <b-row>
                <b-col>
                  <label for="input-chest-j-f">CHEST:</label>
                  <b-input-group :append="jacket.finished.unit" size="sm">
                    <b-form-input id="input-chest-j-f" v-model="jacket.finished.chest"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-stomach-j-f">STOMACH:</label>
                  <b-input-group :append="jacket.finished.unit" size="sm">
                    <b-form-input id="input-stomach-j-f" v-model="jacket.finished.stomach"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="input-hip-j-f">HIP:</label>
                  <b-input-group :append="jacket.finished.unit" size="sm">
                    <b-form-input id="input-hip-j-f" v-model="jacket.finished.hip" style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-bicep-j-f">BICEP:</label>
                  <b-input-group :append="jacket.finished.unit" size="sm">
                    <b-form-input id="input-bicep-j-f" v-model="jacket.finished.bicep"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="input-leftSlvLen-j-f">LEFT SLV LEN:</label>
                  <b-input-group :append="jacket.finished.unit" size="sm">
                    <b-form-input id="input-leftSlvLen-j-f" v-model="jacket.finished.leftSlvLen"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-rightSlvLen-j-f">RIGHT SLV LEN:</label>
                  <b-input-group :append="jacket.finished.unit" size="sm">
                    <b-form-input id="input-rightSlvLen-j-f" v-model="jacket.finished.rightSlvLen"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="input-backJktLen-j-f">BACK JKT LEN:</label>
                  <b-input-group :append="jacket.finished.unit" size="sm">
                    <b-form-input id="input-backJktLen-j-f" v-model="jacket.finished.backJktLen"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-shoulder-j-f">SHOULDER:</label>
                  <b-input-group :append="jacket.finished.unit" size="sm">
                    <b-form-input id="input-shoulder-j-f" v-model="jacket.finished.shoulder"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="input-frontLen-j-f">FRONT LEN:</label>
                  <b-input-group :append="jacket.finished.unit" size="sm">
                    <b-form-input id="input-frontLen-j-f" v-model="jacket.finished.frontLen"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-firstButtonPos-j-f">1ST BTTN POS:</label>
                  <b-input-group :append="jacket.finished.unit" size="sm">
                    <b-form-input id="input-firstButtonPos-j-f" v-model="jacket.finished.firstButtonPos"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="input-cuff-j-f">CUFF:</label>
                  <b-input-group :append="jacket.finished.unit" size="sm">
                    <b-form-input id="input-cuff-j-f" v-model="jacket.finished.cuff"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-neck-j-f">NECK:</label>
                  <b-input-group :append="jacket.finished.unit" size="sm">
                    <b-form-input id="input-neck-j-f" v-model="jacket.finished.neck"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Pant" v-if="clothingTypeArr.indexOf('P')>-1">
        <b-row style="margin-top: 10px;">
          <b-col>
            <b-card header-bg-variant="transparent">
              <template slot="header">
                <b-row align-v="center">
                  <b-col>
                    <span>Body</span>
                  </b-col>
                  <b-col>
                    <b-form-radio-group id="radio-group-pant-body" v-model="pant.body.unit" class="text-center">
                      <b-form-radio value="inch">inch</b-form-radio>
                      <b-form-radio value="cm">cm</b-form-radio>
                    </b-form-radio-group>
                  </b-col>
                </b-row>
              </template>
              <b-row>
                <b-col>
                  <label for="input-waist-p-b">WAIST[PANT WAIST][V]:</label>
                  <b-input-group :append="pant.body.unit" size="sm">
                    <b-form-input id="input-waist-p-b" v-model="pant.body.waist" style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-seat-p-b">SEAT(HIPS):</label>
                  <b-input-group :append="pant.body.unit" size="sm">
                    <b-form-input id="input-seat-p-b" v-model="pant.body.seat" style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="input-thigh-p-b">THIGH:</label>
                  <b-input-group :append="pant.body.unit" size="sm">
                    <b-form-input id="input-thigh-p-b" v-model="pant.body.thigh" style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-uRise-p-b">U-RISE:</label>
                  <b-input-group :append="pant.body.unit" size="sm">
                    <b-form-input id="input-uRise-p-b" v-model="pant.body.uRise" style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="input-backWaistHeight-p-b">BACK WAIST HEIGHT[V]:</label>
                  <b-input-group :append="pant.body.unit" size="sm">
                    <b-form-input id="input-backWaistHeight-p-b" v-model="pant.body.backWaistHeight"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-frontWaistHeight-p-b">FRONT WAIST HEIGHT[V]:</label>
                  <b-input-group :append="pant.body.unit" size="sm">
                    <b-form-input id="input-frontWaistHeight-p-b" v-model="pant.body.frontWaistHeight"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="input-leftOutSeam-p-b">LEFT OUT SEAM:</label>
                  <b-input-group :append="pant.body.unit" size="sm">
                    <b-form-input id="input-leftOutSeam-p-b" v-model="pant.body.leftOutSeam"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-rightOutSeam-p-b">RIGHT OUT SEAM:</label>
                  <b-input-group :append="pant.body.unit" size="sm">
                    <b-form-input id="input-rightOutSeam-p-b" v-model="pant.body.rightOutSeam"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="input-knee-p-b">KNEE:</label>
                  <b-input-group :append="pant.body.unit" size="sm">
                    <b-form-input id="input-knee-p-b" v-model="pant.body.knee" style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-bottom-p-b">BOTTOM:</label>
                  <b-input-group :append="pant.body.unit" size="sm">
                    <b-form-input id="input-bottom-p-b" v-model="pant.body.bottom" style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="input-pantFitStyle-p-b">PANT FIT STYLE:</label>
                  <b-input-group :append="pant.body.unit" size="sm">
                    <b-form-input id="input-pantFitStyle-p-b" v-model="pant.body.pantFitStyle"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col>
            <b-card header-bg-variant="transparent">
              <template slot="header">
                <b-row align-v="center">
                  <b-col>
                    <span>Finished</span>
                  </b-col>
                  <b-col>
                    <b-form-radio-group id="radio-group-pant-finished" v-model="pant.finished.unit" class="text-center">
                      <b-form-radio value="inch">inch</b-form-radio>
                      <b-form-radio value="cm">cm</b-form-radio>
                    </b-form-radio-group>
                  </b-col>
                </b-row>
              </template>
              <b-row>
                <b-col>
                  <label for="input-waist-p-f">WAIST:</label>
                  <b-input-group :append="pant.finished.unit" size="sm">
                    <b-form-input id="input-waist-p-f" v-model="pant.finished.waist"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-seat-p-f">SEAT:</label>
                  <b-input-group :append="pant.finished.unit" size="sm">
                    <b-form-input id="input-seat-p-f" v-model="pant.finished.seat" style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="input-thigh-p-f">THIGH:</label>
                  <b-input-group :append="pant.finished.unit" size="sm">
                    <b-form-input id="input-thigh-p-f" v-model="pant.finished.thigh"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-leftOutSeam-p-f">LEFT OUT SEAM:</label>
                  <b-input-group :append="pant.finished.unit" size="sm">
                    <b-form-input id="input-leftOutSeam-p-f" v-model="pant.finished.leftOutSeam"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="input-rightOutSeam-p-f">RIGHT OUT SEAM:</label>
                  <b-input-group :append="pant.finished.unit" size="sm">
                    <b-form-input id="input-rightOutSeam-p-f" v-model="pant.finished.rightOutSeam"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-knee-p-f">KNEE:</label>
                  <b-input-group :append="pant.finished.unit" size="sm">
                    <b-form-input id="input-knee-p-f" v-model="pant.finished.knee" style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="input-bottom-p-f">BOTTOM:</label>
                  <b-input-group :append="pant.finished.unit" size="sm">
                    <b-form-input id="input-bottom-p-f" v-model="pant.finished.bottom"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-uRise-p-f">U-RISE:</label>
                  <b-input-group :append="pant.finished.unit" size="sm">
                    <b-form-input id="input-uRise-p-f" v-model="pant.finished.uRise"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Vest" v-if="clothingTypeArr.indexOf('V')>-1">
        <b-row style="margin-top: 10px;">
          <b-col>
            <b-card header-bg-variant="transparent">
              <template slot="header">
                <b-row align-v="center">
                  <b-col>
                    <span>Body</span>
                  </b-col>
                  <b-col>
                    <b-form-radio-group id="radio-group-vest-body" v-model="vest.body.unit" class="text-center">
                      <b-form-radio value="inch">inch</b-form-radio>
                      <b-form-radio value="cm">cm</b-form-radio>
                    </b-form-radio-group>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-col>
          <b-col>
            <b-card header-bg-variant="transparent">
              <template slot="header">
                <b-row align-v="center">
                  <b-col>
                    <span>Finished</span>
                  </b-col>
                  <b-col>
                    <b-form-radio-group id="radio-group-vest-finished" v-model="vest.finished.unit" class="text-center">
                      <b-form-radio value="inch">inch</b-form-radio>
                      <b-form-radio value="cm">cm</b-form-radio>
                    </b-form-radio-group>
                  </b-col>
                </b-row>
              </template>
              <b-row>
                <b-col>
                  <label for="input-chest-v-f">CHEST:</label>
                  <b-input-group :append="vest.finished.unit" size="sm">
                    <b-form-input id="input-chest-v-f" v-model="vest.finished.chest"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-stomach-v-f">STOMACH(LOW WAIST):</label>
                  <b-input-group :append="vest.finished.unit" size="sm">
                    <b-form-input id="input-stomach-v-f" v-model="vest.finished.stomach"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="input-backLength-v-f">BACK LENGTH:</label>
                  <b-input-group :append="vest.finished.unit" size="sm">
                    <b-form-input id="input-backLength-v-f" v-model="vest.finished.backLength"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-frontLength-v-f">FRONT LENGTH:</label>
                  <b-input-group :append="vest.finished.unit" size="sm">
                    <b-form-input id="input-frontLength-v-f" v-model="vest.finished.frontLength"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="input-firstButtonPos-v-f">1ST BUTTON:</label>
                  <b-input-group :append="vest.finished.unit" size="sm">
                    <b-form-input id="input-firstButtonPos-v-f" v-model="vest.finished.firstButtonPos"
                                  style="width: 75px;"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  name: "CMeasurement",
  data() {
    return {
      jacket: {
        body: {
          unit: 'inch',
          neck: '',
          chest: '',
          stomach: '',
          seat: '',
          bicep: '',
          shoulder: '',
          leftSlvLen: '',
          rightSlvLen: '',
          frontShoulder: '',
          napeToWaist: '',
          backJktLen: '',
          neckShPt2Wst: '',
          jacketFitStyle: ''
        },
        finished: {
          unit: 'inch',
          chest: '',
          stomach: '',
          hip: '',
          bicep: '',
          leftSlvLen: '',
          rightSlvLen: '',
          backJktLen: '',
          shoulder: '',
          frontLen: '',
          firstButtonPos: '',
          cuff: '',
          neck: ''
        }
      },
      pant: {
        body: {
          unit: 'inch',
          waist: '',
          seat: '',
          thigh: '',
          uRise: '',
          backWaistHeight: '',
          frontWaistHeight: '',
          leftOutSeam: '',
          rightOutSeam: '',
          knee: '',
          bottom: '',
          pantFitStyle: ''
        },
        finished: {
          unit: 'inch',
          waist: '',
          seat: '',
          thigh: '',
          leftOutSeam: '',
          rightOutSeam: '',
          knee: '',
          bottom: '',
          uRise: ''
        }
      },
      vest: {
        body: {
          unit: 'inch',
        },
        finished: {
          unit: 'inch',
          chest: '',
          stomach: '',
          backLength: '',
          frontLength: '',
          firstButtonPos: ''
        }
      }
    };
  },
  props: {
    clothingTypeArr: {
      type: Array,
      required: true
    }
  },
  methods: {
    getMeasurement() {
      let measurement = {};

      if (this.clothingTypeArr.indexOf('C') > -1) {
        Object.assign(measurement, {jacket: this.jacket});
      }
      if (this.clothingTypeArr.indexOf('P') > -1) {
        Object.assign(measurement, {pant: this.pant});
      }
      if (this.clothingTypeArr.indexOf('V') > -1) {
        Object.assign(measurement, {vest: this.vest});
      }

      return measurement;
    }
  }
};
</script>

<style>

</style>