<template>
  <b-container class="c-embroidery" v-if="embroideryArray">
    <b-row v-for="i in rows" :key="'embroidery-r'+i" class="text-center">
      <template v-if="i<=embroideryArray.length">
        <b-col v-for="j in cols" :key="'embroidery-c'+j">
          <template v-if="embroideryArray[i-1] && j<=embroideryArray[i-1].length">
            <b-avatar v-if="embroideryArray[i - 1][j - 1].name===selected" badge square badge-variant="success"
                      size="4rem" :src="embroideryArray[i - 1][j - 1].img" icon="file-earmark-image">
              <template slot="badge">
                <b-icon-check></b-icon-check>
              </template>
            </b-avatar>
            <b-avatar v-else square button size="4rem" :src="embroideryArray[i - 1][j - 1].img"
                      icon="file-earmark-image"
                      @click="handleSelect(embroideryArray[i - 1][j - 1].name)"></b-avatar>
            <p style="padding-top: 5px;">{{ embroideryArray[i - 1][j - 1].name }}</p>
          </template>
        </b-col>
      </template>
    </b-row>
  </b-container>
</template>

<script>
import {embroideryObject} from '../assets/js/resource.js';

export default {
  name: "CEmbroidery",
  data() {
    return {
      rows: 4,
      cols: 4,
      embroideryArray: embroideryObject[this.clothingType][this.embroideryAttr]
    };
  },
  props: {
    clothingType: {
      type: String,
      required: true
    },
    embroideryAttr: {
      type: String,
      required: true
    },
    selected: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleSelect(name) {
      this.$emit('select', name);
    }
  }
};
</script>

<style lang="scss" scoped>
.c-embroidery {
  img {
    cursor: pointer;

    &.selected {
      border: solid red 2px;
    }
  }
}
</style>