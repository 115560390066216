<template>
  <b-container class="c-style" v-if="styleArray">
    <b-row v-for="i in rows" :key="'r'+i" class="text-center">
      <template v-if="i<=styleArray.length">
        <b-col v-for="j in cols" :key="'c'+j">
          <template v-if="styleArray[i-1] && j<=styleArray[i-1].length">
            <b-avatar v-if="styleArray[i - 1][j - 1].name===selected" badge square badge-variant="success"
                      size="4rem" :src="styleArray[i - 1][j - 1].img" icon="file-earmark-image">
              <template slot="badge">
                <b-icon-check></b-icon-check>
              </template>
            </b-avatar>
            <b-avatar v-else square button size="4rem" :src="styleArray[i - 1][j - 1].img" icon="file-earmark-image"
                      @click="handleSelect(styleArray[i - 1][j - 1].name)"></b-avatar>
            <p style="padding-top: 5px;">{{ styleArray[i - 1][j - 1].name }}</p>
          </template>
        </b-col>
      </template>
    </b-row>
  </b-container>
</template>

<script>
import {styleObject} from '../assets/js/resource.js';

export default {
  name: "CStyle",
  data() {
    return {
      rows: 4,
      cols: 4,
      styleArray: styleObject[this.clothingType][this.styleName]
    };
  },
  props: {
    clothingType: {
      type: String,
      required: true
    },
    styleName: {
      type: String,
      required: true
    },
    selected: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleSelect(name) {
      this.$emit('select', name);
    }
  }
};
</script>

<style lang="scss" scoped>
.c-style {
  img {
    cursor: pointer;

    &.selected {
      border: solid red 2px;
    }
  }
}
</style>