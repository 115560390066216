<template>
  <div class="design" :style="{height: designHeight+'px'}">
    <b-overlay :show="loading" rounded="sm">
      <validation-observer ref="vo-design">
        <!-- order -->
        <b-card border-variant="light" style="margin-bottom: 10px;">
          <b-row>
            <b-col cols="2">
              <label for="input-type" class="label-required">Type:</label>
              <validation-provider name="type" rules="required" v-slot="{ errors }">
                <b-input-group>
                  <b-form-select size="sm" v-model="form.order.type" :options="selectOptions.type"
                                 @change="handleChangeType"></b-form-select>
                </b-input-group>
                <span class="valid-error">{{ errors[0] }}</span>
              </validation-provider>
            </b-col>
            <b-col cols="2">
              <label for="input-height" class="label-required">Height:</label>
              <validation-provider name="height" rules="required|number-2" v-slot="{ errors }">
                <b-input-group>
                  <b-form-input id="input-height" size="sm" v-model="form.order.height" placeholder="Height">
                  </b-form-input>
                  <b-input-group-append>
                    <b-form-select size="sm" v-model="form.order.heightUnit"
                                   :options="selectOptions.heightUnit"></b-form-select>
                  </b-input-group-append>
                </b-input-group>
                <span class="valid-error">{{ errors[0] }}</span>
              </validation-provider>
            </b-col>
            <b-col cols="2">
              <label for="input-weight" class="label-required">Weight:</label>
              <validation-provider name="weight" rules="required|number-2" v-slot="{ errors }">
                <b-input-group>
                  <b-form-input id="input-weight" size="sm" v-model="form.order.weight" placeholder="Weight">
                  </b-form-input>
                  <b-input-group-append>
                    <b-form-select size="sm" v-model="form.order.weightUnit"
                                   :options="selectOptions.weightUnit"></b-form-select>
                  </b-input-group-append>
                </b-input-group>
                <span class="valid-error">{{ errors[0] }}</span>
              </validation-provider>
            </b-col>
            <b-col cols="2">
              <label for="input-clientName" class="label-required">ClientName:</label>
              <validation-provider name="clientName" rules="required" v-slot="{ errors }">
                <b-input-group>
                  <b-form-input id="input-clientName" size="sm" v-model="form.order.clientName"
                                placeholder="ClientName">
                  </b-form-input>
                </b-input-group>
                <span class="valid-error">{{ errors[0] }}</span>
              </validation-provider>
            </b-col>
            <b-col cols="4" align-self="center" class="text-center">
              <b-button variant="info" @click.prevent="handleSubmit">
                <span style="font-weight: bold;">Place an order</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>

        <div class="design-detail" :style="{height: designDetailHeight+'px'}">
          <!-- detail -->
          <b-card border-variant="light">
            <template slot="header">
              <b-icon-blockquote-right/>
              <span class="header-title">DETAIL</span>
            </template>
            <template v-if="form.orderDetail.length > 0">
              <b-row inline v-for="(od,i) in form.orderDetail" :key="'od'+i" class="order-detail-row">
                <b-col cols="1">
                  <label>CVP:</label>
                  <b-input-group>
                    <b-avatar size="sm" :text="od.cvp"></b-avatar>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-fabric" class="label-required">Fabric:</label>
                  <validation-provider :name="`fabric_${i}`" rules="required" v-slot="{ errors }">
                    <b-input-group>
                      <b-form-input :id="`input-fabric_${i}`" list="list-fabric" size="sm" v-model="od.fabric"
                                    placeholder="Fabric"></b-form-input>
                      <datalist id="list-fabric">
                        <option v-for="fabric in dataListOption.fabric" :key="fabric">{{ fabric }}</option>
                      </datalist>
                      <b-input-group-append>
                        <b-button variant="outline-secondary" size="sm">
                          <b-icon icon="three-dots" size="sm" aria-hidden="true" @click="showFabricModal(i)"></b-icon>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <span class="valid-error">{{ errors[0] }}</span>
                  </validation-provider>
                </b-col>
                <b-col>
                  <label for="input-bodyLining" class="label-required">Body Lining:</label>
                  <validation-provider :name="`bodyLining_${i}`" rules="required" v-slot="{ errors }">
                    <b-input-group>
                      <b-form-input :id="`input-bodyLining_${i}`" list="list-bodyLining" size="sm"
                                    v-model="od.bodyLining"
                                    placeholder="BodyLining"></b-form-input>
                      <datalist id="list-bodyLining">
                        <option v-for="lining in dataListOption.lining" :key="lining">{{ lining }}</option>
                      </datalist>
                    </b-input-group>
                    <span class="valid-error">{{ errors[0] }}</span>
                  </validation-provider>
                </b-col>
                <b-col>
                  <label for="input-sleeveLining" class="label-required">Sleeve Lining:</label>
                  <validation-provider :name="`sleeveLining_${i}`" rules="required" v-slot="{ errors }">
                    <b-input-group>
                      <b-form-input :id="`input-sleeveLining_${i}`" list="list-sleeveLining" size="sm"
                                    v-model="od.sleeveLining"
                                    placeholder="SleeveLining"></b-form-input>
                      <datalist id="list-sleeveLining">
                        <option v-for="lining in dataListOption.lining" :key="lining">{{ lining }}</option>
                      </datalist>
                    </b-input-group>
                    <span class="valid-error">{{ errors[0] }}</span>
                  </validation-provider>
                </b-col>
                <b-col>
                  <label for="input-remark">Remark:</label>
                  <b-input-group size="sm">
                    <b-form-input :id="`input-remark_${i}`" v-model="od.remark" placeholder="Remark"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
            </template>
            <template v-else>
              Please Select Order Type First!
            </template>
          </b-card>

          <!-- style -->
          <b-card border-variant="light">
            <template slot="header">
              <b-icon-blockquote-right/>
              <span class="header-title">STYLE</span>
            </template>
            <template v-if="form.orderDetail.length > 0">
              <b-tabs>
                <b-tab title="Jacket" v-if="clothingTypeArr.indexOf('C')>-1">
                  <b-row style="padding-top: 10px;">
                    <b-col>
                      <label for="input-jacket-style-lapel">Lapel Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-jacket-style-lapel" size="sm" v-model="form.style.jacket.lapel"
                                       :options="selectOptions.style.jacket.lapel"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('jacket', 'lapel')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <label for="input-jacket-style-frontButton">Front Button Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-jacket-style-frontButton" size="sm"
                                       v-model="form.style.jacket.frontButton"
                                       :options="selectOptions.style.jacket.frontButton"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('jacket', 'frontButton')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <label for="input-jacket-style-lowerPocket">Lower Pocket Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-jacket-style-lowerPocket" size="sm"
                                       v-model="form.style.jacket.lowerPocket"
                                       :options="selectOptions.style.jacket.lowerPocket"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('jacket', 'lowerPocket')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <label for="input-jacket-style-breastPocket">Breast Pocket Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-jacket-style-breastPocket" size="sm"
                                       v-model="form.style.jacket.breastPocket"
                                       :options="selectOptions.style.jacket.breastPocket"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('jacket', 'breastPocket')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                  </b-row>
                  <b-row style="padding-top: 10px;">
                    <b-col>
                      <label for="input-jacket-style-backVent">Back Vent Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-jacket-style-backVent" size="sm"
                                       v-model="form.style.jacket.backVent"
                                       :options="selectOptions.style.jacket.backVent"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('jacket', 'backVent')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <label for="input-jacket-style-pickStitching">Pick Stitching Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-jacket-style-pickStitching" size="sm"
                                       v-model="form.style.jacket.pickStitching"
                                       :options="selectOptions.style.jacket.pickStitching"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('jacket', 'pickStitching')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <label for="input-jacket-style-sleeveSlitButton">Sleeve Slit And Button Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-jacket-style-sleeveSlitButton" size="sm"
                                       v-model="form.style.jacket.sleeveSlitButton"
                                       :options="selectOptions.style.jacket.sleeveSlitButton"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('jacket', 'sleeveSlitButton')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col></b-col>
                  </b-row>
                </b-tab>
                <b-tab title="Pant" v-if="clothingTypeArr.indexOf('P')>-1">
                  <b-row style="padding-top: 10px;">
                    <b-col>
                      <label for="input-pant-style-frontPleats">Front Pleat Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-pant-style-frontPleats" size="sm"
                                       v-model="form.style.pant.frontPleats"
                                       :options="selectOptions.style.pant.frontPleats"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('pant', 'frontPleats')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <label for="input-pant-style-frontPocket">Front Pocket Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-pant-style-frontPocket" size="sm"
                                       v-model="form.style.pant.frontPocket"
                                       :options="selectOptions.style.pant.frontPocket"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('pant', 'frontPocket')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <label for="input-pant-style-beltLoop">Belt Loop Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-pant-style-beltLoop" size="sm"
                                       v-model="form.style.pant.beltLoop"
                                       :options="selectOptions.style.pant.beltLoop"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('pant', 'beltLoop')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <label for="input-pant-style-backPocket">Back Pocket Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-pant-style-backPocket" size="sm"
                                       v-model="form.style.pant.backPocket"
                                       :options="selectOptions.style.pant.backPocket"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('pant', 'backPocket')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                  </b-row>
                  <b-row style="padding-top: 10px;">
                    <b-col>
                      <label for="input-pant-style-sideWaistband">Side Waistband Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-pant-style-sideWaistband" size="sm"
                                       v-model="form.style.pant.sideWaistband"
                                       :options="selectOptions.style.pant.sideWaistband"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('pant', 'sideWaistband')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <label for="input-pant-style-innerWaistband">Inner Waistband Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-pant-style-innerWaistband" size="sm"
                                       v-model="form.style.pant.innerWaistband"
                                       :options="selectOptions.style.pant.innerWaistband"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('pant', 'innerWaistband')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <label for="input-pant-style-pantBottom">Pant Bottom Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-pant-style-pantBottom" size="sm"
                                       v-model="form.style.pant.pantBottom"
                                       :options="selectOptions.style.pant.pantBottom"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('pant', 'pantBottom')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <label for="input-pant-style-pantLining">Pant Lining Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-pant-style-pantLining" size="sm"
                                       v-model="form.style.pant.pantLining"
                                       :options="selectOptions.style.pant.pantLining"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('pant', 'pantLining')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab title="Vest" v-if="clothingTypeArr.indexOf('V')>-1">
                  <b-row style="padding-top: 10px;">
                    <b-col>
                      <label for="input-vest-style-collar">Collar Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-vest-style-collar" size="sm"
                                       v-model="form.style.vest.collar"
                                       :options="selectOptions.style.vest.collar"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('vest', 'collar')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <label for="input-vest-style-frontButton">Front Button Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-vest-style-frontButton" size="sm"
                                       v-model="form.style.vest.frontButton"
                                       :options="selectOptions.style.vest.frontButton"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('vest', 'frontButton')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <label for="input-vest-style-bottom">Bottom Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-vest-style-bottom" size="sm"
                                       v-model="form.style.vest.bottom"
                                       :options="selectOptions.style.vest.bottom"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('vest', 'bottom')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <label for="input-vest-style-lowerBreastPocket">Lower Pocket & Breast Pocket Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-vest-style-lowerBreastPocket" size="sm"
                                       v-model="form.style.vest.lowerBreastPocket"
                                       :options="selectOptions.style.vest.lowerBreastPocket"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('vest', 'lowerBreastPocket')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                  </b-row>
                  <b-row style="padding-top: 10px;">
                    <b-col>
                      <label for="input-vest-style-inner">Inner Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-vest-style-inner" size="sm"
                                       v-model="form.style.vest.inner"
                                       :options="selectOptions.style.vest.inner"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('vest', 'inner')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <label for="input-vest-style-back">Back Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-vest-style-back" size="sm"
                                       v-model="form.style.vest.back"
                                       :options="selectOptions.style.vest.back"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('vest', 'back')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <label for="input-vest-style-tuxedo">Tuxedo Style:</label>
                      <b-input-group size="sm">
                        <b-form-select id="input-vest-style-tuxedo" size="sm"
                                       v-model="form.style.vest.tuxedo"
                                       :options="selectOptions.style.vest.tuxedo"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showStyleModal('vest', 'tuxedo')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </template>
            <template v-else>
              Please Select Order Type First!
            </template>
          </b-card>

          <!-- embroidery -->
          <b-card border-variant="light">
            <template slot="header">
              <b-icon-blockquote-right/>
              <span class="header-title">EMBROIDERY</span>
            </template>
            <template v-if="form.orderDetail.length > 0">
              <b-tabs>
                <b-tab title="Jacket" v-if="clothingTypeArr.indexOf('C')>-1">
                  <b-row v-for="(eb,i) in form.embroidery.jacket" :key="'eb'+i" style="padding-top: 10px;">
                    <b-col>
                      <label :for="`input-jacket-letter_${i}`">Letter:</label>
                      <b-input-group size="sm">
                        <b-form-input :id="`input-jacket-letter_${i}`" v-model="eb.letter"
                                      placeholder="Letter"></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <label :for="`input-jacket-position_${i}`">Position:</label>
                      <b-input-group size="sm">
                        <b-form-select :id="`input-jacket-position_${i}`" v-model="eb.position"
                                       :options="selectOptions.embroidery.jacket.position"></b-form-select>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <label :for="`input-jacket-font_${i}`">Font:</label>
                      <b-input-group size="sm">
                        <b-form-select :id="`input-jacket-font_${i}`" v-model="eb.font"
                                       :options="selectOptions.embroidery.jacket.font"></b-form-select>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" size="sm">
                            <b-icon icon="three-dots" size="sm" aria-hidden="true"
                                    @click="showEmbroideryModal(i, 'jacket', 'font')"></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <label :for="`input-jacket-color_${i}`">Color:</label>
                      <b-input-group size="sm">
                        <b-form-select :id="`input-jacket-color_${i}`" v-model="eb.color"
                                       :options="selectOptions.embroidery.jacket.color"></b-form-select>
                      </b-input-group>
                    </b-col>
                    <b-col cols="1" align-self="center">
                      <b-button variant="link"
                                v-if="i===form.embroidery.jacket.length-1 && form.embroidery.jacket.length<=1"
                                @click="addEmbroidery('jacket')">
                        <b-icon-file-earmark-plus/>
                      </b-button>
                      <b-button variant="link"
                                v-if="i===form.embroidery.jacket.length-1 && form.embroidery.jacket.length>1"
                                @click="delEmbroidery('jacket', i)">
                        <b-icon-file-earmark-minus/>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab title="Pant" v-if="clothingTypeArr.indexOf('P')>-1">
                  <div style="padding-top: 10px;">No need to select.</div>
                </b-tab>
                <b-tab title="Vest" v-if="clothingTypeArr.indexOf('V')>-1">
                  <div style="padding-top: 10px;">No need to select.</div>
                </b-tab>
              </b-tabs>
            </template>
            <template v-else>
              Please Select Order Type First!
            </template>
          </b-card>

          <!-- measurement -->
          <b-card border-variant="light">
            <template slot="header">
              <b-icon-blockquote-right/>
              <span class="header-title">MEASUREMENT</span>
            </template>
            <template v-if="clothingTypeArr.length > 0">
              <c-measurement ref="measurement" :clothing-type-arr="clothingTypeArr"></c-measurement>
            </template>
            <template v-else>
              Please Select Order Type First!
            </template>
          </b-card>

          <!-- body description -->
          <b-card border-variant="light">
            <template slot="header">
              <b-icon-blockquote-right/>
              <span class="header-title">BODY DESCRIPTION</span>
            </template>
            <template v-if="clothingTypeArr.length > 0">
              <b-row v-if="clothingTypeArr.indexOf('C')>-1">
                <b-col>
                  <label for="input-frontShoulder">FRONT SHOULDER:</label>
                  <b-input-group size="sm">
                    <b-form-select v-model="form.bodyDescription.frontShoulder"
                                   :options="selectOptions.bodyDescription.frontShoulder"></b-form-select>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-backBody">BACK BODY:</label>
                  <b-input-group size="sm">
                    <b-form-select v-model="form.bodyDescription.backBody"
                                   :options="selectOptions.bodyDescription.backBody"></b-form-select>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-leftShoulder">LEFT SHOULDER:</label>
                  <b-input-group size="sm">
                    <b-form-select v-model="form.bodyDescription.leftShoulder"
                                   :options="selectOptions.bodyDescription.shoulder"></b-form-select>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-rightShoulder">RIGHT SHOULDER:</label>
                  <b-input-group size="sm">
                    <b-form-select v-model="form.bodyDescription.rightShoulder"
                                   :options="selectOptions.bodyDescription.shoulder"></b-form-select>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row v-if="clothingTypeArr.indexOf('C')>-1">
                <b-col>
                  <label for="input-arm">ARM:</label>
                  <b-input-group size="sm">
                    <b-form-select v-model="form.bodyDescription.arm"
                                   :options="selectOptions.bodyDescription.arm"></b-form-select>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label for="input-belly">BELLY:</label>
                  <b-input-group size="sm">
                    <b-form-select v-model="form.bodyDescription.belly"
                                   :options="selectOptions.bodyDescription.belly"></b-form-select>
                  </b-input-group>
                </b-col>
                <b-col></b-col>
                <b-col></b-col>
              </b-row>
              <b-row v-if="clothingTypeArr.indexOf('P')>-1">
                <b-col>
                  <label for="input-pantHip">PANT-HIP:</label>
                  <b-input-group size="sm">
                    <b-form-select v-model="form.bodyDescription.pantHip"
                                   :options="selectOptions.bodyDescription.pantHip"></b-form-select>
                  </b-input-group>
                </b-col>
                <b-col></b-col>
                <b-col></b-col>
                <b-col></b-col>
              </b-row>
            </template>
            <template v-else>
              Please Select Order Type First!
            </template>
          </b-card>
        </div>
      </validation-observer>
    </b-overlay>

    <!-- modal:fabric -->
    <b-modal id="modal-fabric" title="Fabric Select" ok-only ok-title="确定" @ok.prevent="handleSelectFabric">
      <c-fabric :selected="current.orderDetail.fabric" @select="selectFabric"></c-fabric>
    </b-modal>

    <!-- modal:style -->
    <b-modal id="modal-style" title="Style Select" ok-only ok-title="确定" @ok.prevent="handleSelectStyle">
      <c-style :clothing-type="current.style.clothingType" :style-name="current.style.name"
               :selected="current.style.row[current.style.name]"
               @select="selectStyle"></c-style>
    </b-modal>

    <!-- modal:embroidery -->
    <b-modal id="modal-embroidery" title="Embroidery Select" ok-only ok-title="确定" @ok.prevent="handleSelectEmbroidery">
      <c-embroidery :clothing-type="current.embroidery.clothingType" :embroidery-attr="current.embroidery.attr"
                    :selected="current.embroidery.row[current.embroidery.attr]"
                    @select="selectEmbroidery"></c-embroidery>
    </b-modal>
  </div>
</template>

<script>
import {dataListOption, selectOptions} from '../assets/js/resource.js';
import {type2ClothingTypeArr} from "../assets/js/util";
import CFabric from '../components/CFabric';
import CStyle from '../components/CStyle';
import CEmbroidery from '../components/CEmbroidery';
import CMeasurement from '../components/CMeasurement';

const formObj = {
  order: {
    type: '',
    height: '',
    heightUnit: 'inch',
    weight: '',
    weightUnit: 'kg',
    clientName: ''
  },
  orderDetail: [],
  style: {
    jacket: {
      lapel: '',
      frontButton: '',
      lowerPocket: '',
      breastPocket: '',
      backVent: '',
      pickStitching: '',
      sleeveSlitButton: ''
    },
    pant: {
      frontPleats: '',
      frontPocket: '',
      beltLoop: '',
      backPocket: '',
      sideWaistband: '',
      innerWaistband: '',
      pantBottom: '',
      pantLining: ''
    },
    vest: {
      collar: '',
      frontButton: '',
      bottom: '',
      lowerBreastPocket: '',
      inner: '',
      back: '',
      tuxedo: ''
    }
  },
  embroidery: {
    jacket: [
      {
        letter: '',
        position: '',
        font: '',
        color: ''
      }
    ]
  },
  bodyDescription: {
    frontShoulder: '',
    backBody: '',
    leftShoulder: '',
    rightShoulder: '',
    arm: '',
    belly: '',
    pantHip: ''
  }
};

export default {
  name: 'design',
  data() {
    return {
      loading: false,
      form: JSON.parse(JSON.stringify(formObj)),
      state: {
        order: {
          type: null
        }
      },
      clothingTypeArr: [],
      selectOptions: selectOptions,
      dataListOption: dataListOption,
      // 当前操作的记录
      current: {
        // 订单明细
        orderDetail: {},
        style: {
          // 服装分类
          clothingType: '',
          // 款式名称
          name: '',
          // 款式记录行
          row: {}
        },
        embroidery: {
          // 刺绣属性
          attr: '',
          // 刺绣记录行
          row: {}
        }
      }
    };
  },
  components: {
    CFabric, CStyle, CEmbroidery, CMeasurement
  },
  computed: {
    designHeight: function () {
      return window.innerHeight - 20 - 18 - 28 - 23;
    },
    designDetailHeight: function () {
      return window.innerHeight - 20 - 18 - 28 - 99 - 10 - 23;
    }
  },
  methods: {
    handleChangeType(value) {
      this.form.orderDetail = [];

      this.clothingTypeArr = type2ClothingTypeArr(value);
      if (this.clothingTypeArr.length > 0) {
        // 2pcs || 3pcs
        if (this.clothingTypeArr.indexOf('C') > -1 && this.clothingTypeArr.indexOf('P') > -1) {
          this.form.orderDetail.push(
              {
                cvp: 'CP',
                fabric: '',
                bodyLining: '',
                sleeveLining: '',
                remark: ''
              }
          );
        } else {
          // jacket
          if (this.clothingTypeArr.indexOf('C') > -1) {
            this.form.orderDetail.push(
                {
                  cvp: 'C',
                  fabric: '',
                  bodyLining: '',
                  sleeveLining: '',
                  remark: ''
                }
            );
          }
          // pant
          else if (this.clothingTypeArr.indexOf('P') > -1) {
            this.form.orderDetail.push(
                {
                  cvp: 'P',
                  fabric: '',
                  bodyLining: '',
                  sleeveLining: '',
                  remark: ''
                }
            );
          }
        }

        // 3pcs || vest
        if (this.clothingTypeArr.indexOf('V') > -1) {
          this.form.orderDetail.push(
              {
                cvp: 'V',
                fabric: '',
                bodyLining: '',
                sleeveLining: '',
                remark: ''
              }
          );
        }
      }
    },
    showFabricModal(index) {
      this.current.orderDetail = this.form.orderDetail[index];

      this.$bvModal.show('modal-fabric');
    },
    selectFabric(value) {
      Object.assign(this.current.orderDetail, {
        fabric: value
      });
    },
    handleSelectFabric() {
      if (this.current.orderDetail.fabric === '') {
        this.$bvToast.toast('请选择面料', {
          title: '系统提示',
          variant: 'danger',
          solid: true
        });
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-fabric');
      });
    },
    showStyleModal(clothingType, name) {
      Object.assign(this.current.style, {
        row: this.form.style[clothingType],
        clothingType: clothingType,
        name: name
      });

      this.$bvModal.show('modal-style');
    },
    selectStyle(value) {
      Object.assign(this.current.style.row, {
        [this.current.style.name]: value
      });
    },
    handleSelectStyle() {
      if (this.current.style.row[this.current.style.name] === '') {
        this.$bvToast.toast('请选择款式', {
          title: '系统提示',
          variant: 'danger',
          solid: true
        });
        return;
      }
      this.$nextTick(() => {
        this.$bvModal.hide('modal-style');
      });
    },
    showEmbroideryModal(index, clothingType, attr) {
      Object.assign(this.current.embroidery, {
        row: this.form.embroidery[clothingType][index],
        clothingType: clothingType,
        attr: attr
      })

      this.$bvModal.show('modal-embroidery');
    },
    selectEmbroidery(value) {
      Object.assign(this.current.embroidery.row, {
        [this.current.embroidery.attr]: value
      });
    },
    handleSelectEmbroidery() {
      if (this.current.embroidery.row[this.current.embroidery.attr] === '') {
        this.$bvToast.toast('请选择刺绣属性', {
          title: '系统提示',
          variant: 'danger',
          solid: true
        });
        return;
      }
      this.$nextTick(() => {
        this.$bvModal.hide('modal-embroidery');
      });
    },
    addEmbroidery(clothingType) {
      this.form.embroidery[clothingType].push(
          {
            letter: '',
            position: '',
            font: '',
            color: ''
          }
      );
    },
    delEmbroidery(clothingType, index) {
      this.form.embroidery[clothingType].splice(index, 1);
    },
    handleSubmit() {
      this.$refs['vo-design'].validate()
          .then((valid) => {
            if (valid) {
              this.$bvModal.msgBoxConfirm('Are you sure want to submit?', {
                title: 'Please Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'info',
                okTitle: 'Confirm',
                cancelTitle: 'Cancel'
              })
                  .then(value => {
                    if (value) {
                      this.loading = true;

                      // 处理参数
                      if (this.clothingTypeArr.indexOf('C') <= -1) {
                        delete this.form.embroidery.jacket;
                        delete this.form.style.jacket;
                      }
                      if (this.clothingTypeArr.indexOf('P') <= -1) {
                        delete this.form.embroidery.pant;
                        delete this.form.style.pant;
                      }
                      if (this.clothingTypeArr.indexOf('V') <= -1) {
                        delete this.form.embroidery.vest;
                        delete this.form.style.vest;
                      }

                      this.$axios.post('order', {
                        orderStr: JSON.stringify(Object.assign({
                          measurement: this.$refs['measurement'].getMeasurement()
                        }, this.form))
                      })
                          .then(res => {
                            this.loading = false;

                            if (res.s === 0) {
                              this.$bvToast.toast('下单成功：' + res.data, {
                                title: '系统提示',
                                variant: 'success',
                                solid: true
                              });

                              this.resetForm();
                            } else {
                              this.$bvToast.toast(res.msg, {
                                title: '系统提示',
                                variant: 'danger',
                                solid: true
                              });
                            }
                          })
                          .catch(err => {
                            this.loading = false;

                            console.error(err);
                          });
                    }
                  })
                  .catch(err => {
                    console.error(err);
                  })
            }
          })
          .catch(e => {
            console.error(e);
          });
    },
    resetForm() {
      this.form = JSON.parse(JSON.stringify(formObj));

      this.$refs['vo-design'].reset();
    }
  }
}
</script>

<style lang="scss">
.design {
  width: 100%;
  overflow-y: auto;

  .design-detail {
    overflow-y: auto;
  }

  .label-required:before {
    content: '*';
    color: red;
    padding-right: 5px;
  }

  .header-title {
    color: cornflowerblue;
    font-weight: bold;
    padding-left: 10px;
  }

  .order-detail-row:not(:first-child) {
    margin-top: 10px;
  }
}
</style>
